import logo from './images/nextgen-logo-transparent.png';
import './App.scss';

function App() {
  return (
    <div>
      <div className="text-center p-20 mv-50">
        <img src={logo} className="hero-logo" alt="logo" />
      </div>
      <div className="bg-primary text-center p-50 mv-50">
        info@ngpropertygroup.com
      </div>
    </div>
  );
}

export default App;
